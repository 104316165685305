import { Button as MuiButton, styled, alpha } from '@mui/material';

import ButtonProps from '../types';

const PrimaryStyled = styled(MuiButton, {
    shouldForwardProp: (prop: string) => !['elevated', 'center', 'paletteMode', 'overrideColor'].includes(prop),
})<ButtonProps>(({ theme, center, elevated, overrideColor }) => ({
    borderRadius: theme.radius.all,
    color: theme.palette.cta.contrastText,
    backgroundColor: overrideColor || theme.palette.cta.main,
    boxShadow: elevated ? theme.shadows[2] : 'none',
    justifyContent: center ? 'center' : 'flex-start',
    '&.MuiButton-sizeSmall': {
        fontSize: 14,
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        height: theme.spacing(8),
    },
    '&.MuiButton-sizeMedium': {
        fontSize: 15,
        paddingRight: theme.spacing(5),
        paddingLeft: theme.spacing(5),
        height: theme.spacing(10),
    },
    '&.MuiButton-sizeLarge': {
        fontSize: 16,
        paddingRight: theme.spacing(6),
        paddingLeft: theme.spacing(6),
        height: theme.spacing(13),
    },
    '&:hover, &.Mui-hoverVisible': {
        color: theme.palette.cta.contrastText,
        backgroundColor: overrideColor ? alpha(overrideColor, 0.9) : theme.palette.cta.dark,
    },
    '&.Mui-focusVisible': {
        boxShadow: theme.shadows[24],
    },
    '&.Mui-disabled': {
        opacity: '0.6',
    },
}));

export default PrimaryStyled;
