import React from 'react';

import ButtonProps from './types';

import LinkStyled from './components/Link';
import PrimaryStyled from './components/Primary';
import QuaternaryStyled from './components/Quaternary';
import SecondaryStyled from './components/Secondary';
import TertiaryStyled from './components/Tertiary';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            selected,
            variant,
            startIcon,
            endIcon,
            overrideColor,
            elevated,
            stroked,
            center,
            wrapContent,
            size,
            ...props
        }: ButtonProps,
        ref,
    ) => {
        if (variant === 'quaternary') {
            return (
                <QuaternaryStyled
                    ref={ref}
                    size={size}
                    selected={selected}
                    variant={variant}
                    startIcon={startIcon}
                    endIcon={endIcon}
                    center={center}
                    stroked={stroked}
                    wrapContent={wrapContent}
                    elevated={elevated}
                    className={selected ? 'Mui-selected' : ''}
                    {...props}
                />
            );
        }

        if (variant === 'primary') {
            return (
                <PrimaryStyled
                    ref={ref}
                    size={size}
                    selected={selected}
                    variant={variant}
                    overrideColor={overrideColor}
                    startIcon={startIcon}
                    center={center}
                    endIcon={endIcon}
                    elevated={elevated}
                    {...props}
                />
            );
        }

        if (variant === 'secondary') {
            return (
                <SecondaryStyled
                    ref={ref}
                    size={size}
                    overrideColor={overrideColor}
                    selected={selected}
                    variant={variant}
                    startIcon={startIcon}
                    endIcon={endIcon}
                    elevated={elevated}
                    center={center}
                    {...props}
                />
            );
        }

        if (variant === 'link') {
            return (
                <LinkStyled
                    ref={ref}
                    size={size}
                    selected={selected}
                    variant={variant}
                    startIcon={startIcon}
                    overrideColor={overrideColor}
                    endIcon={endIcon}
                    elevated={elevated}
                    {...props}
                />
            );
        }

        return (
            <TertiaryStyled
                ref={ref}
                size={size}
                selected={selected}
                variant={variant}
                overrideColor={overrideColor}
                startIcon={startIcon}
                endIcon={endIcon}
                elevated={elevated}
                {...props}
            />
        );
    },
);

Button.displayName = 'Button';

export default Button;
