import { Button as MuiButton, alpha, styled } from '@mui/material';
import ButtonProps from '../types';

const SecondaryStyled = styled(MuiButton, {
    shouldForwardProp: (prop: string) => !['elevated', 'center', 'paletteMode', 'overrideColor'].includes(prop),
})<ButtonProps>(({ theme, center, elevated, paletteMode, overrideColor }) => {
    const pMode = paletteMode || theme.palette.mode;

    return {
        borderRadius: theme.radius.all,
        color: overrideColor || (pMode === 'dark' ? theme.palette.common.white : theme.palette.cta.main),
        backgroundColor: overrideColor
            ? alpha(overrideColor, 0.07)
            : pMode === 'dark'
              ? alpha(theme.palette.common.white, 0.07)
              : alpha(theme.palette.cta.main, 0.07),
        boxShadow: elevated ? theme.shadows[2] : 'none',
        justifyContent: center ? 'center' : 'flex-start',
        '&.MuiButton-sizeSmall': {
            fontSize: 14,
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            height: theme.spacing(8),
        },
        '&.MuiButton-sizeMedium': {
            fontSize: 15,
            paddingRight: theme.spacing(5),
            paddingLeft: theme.spacing(5),
            height: theme.spacing(10),
        },
        '&.MuiButton-sizeLarge': {
            fontSize: 16,
            paddingRight: theme.spacing(6),
            paddingLeft: theme.spacing(6),
            height: theme.spacing(13),
        },
        '&:hover, &.Mui-hoverVisible': {
            backgroundColor: overrideColor
                ? alpha(overrideColor, 0.15)
                : pMode === 'dark'
                  ? alpha(theme.palette.common.white, 0.2)
                  : alpha(theme.palette.cta.main, 0.15),
        },
        '&.Mui-focusVisible': {
            boxShadow: theme.shadows[24],
        },
        '&.Mui-disabled': {
            opacity: '0.6',
        },
    };
});

export default SecondaryStyled;
