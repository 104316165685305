import { Button as MuiButton, styled } from '@mui/material';
import ButtonProps from '../types';

const QuaternaryStyled = styled(MuiButton, {
    shouldForwardProp: (prop: string) => !['stroked', 'elevated', 'center', 'wrapContent'].includes(prop),
})<ButtonProps>(({ theme, stroked, elevated, center, wrapContent }) => ({
    whiteSpace: wrapContent ? 'normal' : 'nowrap',
    borderRadius: theme.radius.all,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.background.light,
    color: theme.palette.text.primary,
    border: stroked ? `1px solid ${theme.palette.grey[300]}` : '1px solid transparent',
    boxShadow: elevated ? theme.shadows[1] : 'none',
    justifyContent: center ? 'center' : 'flex-start',
    '& .MuiButton-startIcon': {
        marginRight: 14,
    },
    '&.MuiButton-sizeSmall': {
        fontSize: 12,
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        height: theme.spacing(8),
    },
    '&.MuiButton-sizeMedium': {
        fontSize: 14,
        paddingRight: theme.spacing(5),
        paddingLeft: theme.spacing(5),
        height: theme.spacing(10),
    },
    '&.MuiButton-sizeLarge': {
        fontSize: 16,
        paddingRight: theme.spacing(6),
        paddingLeft: theme.spacing(6),
        height: theme.spacing(13),
    },
    '&:hover, &.Mui-hoverVisible': {
        boxShadow: theme.shadows[2],
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.background.lighter,
    },
    '&.Mui-focusVisible': {
        boxShadow: theme.shadows[24],
    },
    '&.Mui-disabled': {
        opacity: '0.6',
    },
    '&.Mui-selected': {
        border: `1px solid ${theme.palette.cta.main}`,
    },
}));

export default QuaternaryStyled;
