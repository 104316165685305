import React, { forwardRef } from 'react';
import { Typography, TypographyProps } from '@mui/material';

interface HeadingProps extends TypographyProps {
    component?: React.ElementType;
}

const Heading = forwardRef<HTMLSpanElement, HeadingProps>((props, ref) => {
    // TODO, needs to support tag type, want the styling from h1, but it needs to be a h2 tag in the DOM

    const variant = props.variant || 'h2';

    return <Typography ref={ref} variant={variant} {...props} />;
});

Heading.displayName = 'Heading';
export default Heading;
