import { Button as MuiButton, styled, alpha } from '@mui/material';
import ButtonProps from '../types';

const TertiaryStyled = styled(MuiButton, {
    shouldForwardProp: (prop: string) => !['elevated', 'paletteMode', 'overrideColor'].includes(prop),
})<ButtonProps>(({ theme, elevated, paletteMode, overrideColor }) => {
    const pMode = paletteMode || theme.palette.mode;
    return {
        borderRadius: theme.radius.all,
        color: overrideColor || (pMode === 'dark' ? theme.palette.common.white : theme.palette.cta.main),
        backgroundColor: 'transparent',
        boxShadow: elevated ? theme.shadows[2] : 'none',
        '&.MuiButton-sizeSmall': {
            fontSize: 12,
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            height: theme.spacing(8),
        },
        '&.MuiButton-sizeMedium': {
            fontSize: 14,
            paddingRight: theme.spacing(5),
            paddingLeft: theme.spacing(5),
            height: theme.spacing(10),
        },
        '&.MuiButton-sizeLarge': {
            fontSize: 16,
            paddingRight: theme.spacing(6),
            paddingLeft: theme.spacing(6),
            height: theme.spacing(13),
        },
        '&:hover, &.Mui-hoverVisible': {
            backgroundColor: overrideColor
                ? alpha(overrideColor, 0.07)
                : pMode === 'dark'
                  ? theme.palette.mode === 'light'
                      ? alpha(theme.palette.common.black, 0.2)
                      : alpha(theme.palette.common.white, 0.1)
                  : theme.palette.mode === 'light'
                    ? alpha(theme.palette.cta.main, 0.05)
                    : alpha(theme.palette.common.white, 0.1),
        },
        '&.Mui-focusVisible': {
            boxShadow: theme.shadows[24],
        },
        '&.Mui-disabled': {
            opacity: '0.6',
        },
    };
});

export default TertiaryStyled;
