import { Button as MuiButton, styled, alpha } from '@mui/material';

import ButtonProps from '../types';

const LinkStyled = styled(MuiButton, {
    shouldForwardProp: (prop: string) => !['overrideColor'].includes(prop),
})<ButtonProps>(({ theme, overrideColor }) => ({
    color: overrideColor || (theme.palette.mode === 'light' ? theme.palette.cta.main : theme.palette.cta.light),
    fontWeight: 600,
    fontSize: 'inherit',
    padding: 0,
    height: 'auto',
    minWidth: 'auto',
    letterSpacing: '0',
    whiteSpace: 'normal',
    wordWrap: 'normal',
    verticalAlign: 'baseline',
    display: 'inline',
    lineHeight: 'inherit',
    borderTop: '2px solid transparent',
    borderBottom: `2px solid ${
        overrideColor
            ? alpha(overrideColor, 0.3)
            : theme.palette.mode === 'light'
              ? alpha(theme.palette.cta.main, 0.3)
              : alpha(theme.palette.common.white, 0.5)
    }`,
    '&:hover, &.Mui-hoverVisible': {
        borderBottom: `2px solid ${
            theme.palette.mode === 'light' ? theme.palette.cta.main : theme.palette.common.white
        }`,
    },
    '&.Mui-focusVisible': {
        boxShadow: theme.shadows[24],
    },
    '&.Mui-disabled': {
        opacity: '0.6',
    },
}));

export default LinkStyled;
